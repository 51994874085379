<template>
  <div class="new-place">
    <div class="container">
      <div class="new-place__content">
        <div class="new-place__content--left">
          <h2>Новая площадка форума</h2>
          <p>
            Летний форум в удивительном городе Альметьевске обещает стать
            незабываемым событием для всех студентов.
          </p>
          <p>
            Высшая школа нефти, признанная одним из самых перспективных и
            престижных вузов страны, восхищает своим новым кампусом — настоящим
            «городом в городе». Здесь каждый уголок создан с заботой о
            студентах, обеспечивая всю необходимую инфраструктуру для
            комфортного проживания и успешного обучения.
          </p>
          <p>
            Современные аудитории, лаборатории, спортивные площадки, библиотеки
            и общежития — все это сосредоточено на территории кампуса, чтобы
            обеспечить студентам самые благоприятные условия для развития и
            самореализации. Атмосфера инноваций и академического превосходства
            наполнит каждого участника форума вдохновением и желанием достичь
            новых высот.
          </p>
        </div>
        <div class="new-place__content--right">
          <Carousel
            ref="carousel"
            class="new-place__carousel"
            :wrap-around="true"
            :settings="settings"
          >
            <Slide
              v-for="(item, index) in placeSliderImages"
              :key="index"
              class="new-place__carousel-item"
            >
              <img :src="item.img" alt="" />
            </Slide>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import placeSliderImages from "@/enums/placeSliderImages";
import { Carousel, Slide } from "vue3-carousel";
export default {
  name: "HomeNewPlace",
  components: { Carousel, Slide },
  data() {
    return {
      placeSliderImages,
      settings: {
        itemsToScroll: 1,
        wrapAround: true,
        autoplay: 3000,
        mouseDrag: false,
        touchDrag: false,
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.carousel.updateSlideWidth();
    }, 400);
  },
};
</script>

<style lang="scss">
.new-place {
  margin-top: 60px;
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    &--left {
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: flex-start;
      p {
        max-width: 570px;
      }
    }
    &--right {
      border-radius: 15px;
      overflow: hidden;
      max-width: 50%;
    }

    img {
      flex-shrink: 1;
      object-fit: cover;
      height: 100%;
    }
  }
  &__carousel {
    .carousel {
      &__viewport {
        width: 100%;
      }
      &__track {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
      }
    }
    &-item {
      min-width: 100%;
    }
  }
  @include adaptive(tablet-small) {
    &__content {
      flex-direction: column;
      &--right {
        max-width: unset;
      }
    }
  }
}
</style>
