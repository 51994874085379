<template>
  <div class="stages">
    <div class="container">
      <div class="stages__content">
        <h2>Этапы форума</h2>
        <div class="stages__list">
          <div
            class="stages-item__container"
            v-for="(item, index) in info"
            :key="index"
          >
            <div class="stages-item">
              <div class="stages-item__title">Этап {{ item.sort }}</div>
              <div class="stages-item__desc">{{ item.title }}</div>
              <div class="stages-item__date">
                {{ item.mini_description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";

export default {
  name: "HomeStages",
  data() {
    return {
      info: [],
    };
  },
  beforeMount() {
    if (!this.$store.state.storage.storageStagesData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "stages_forum",
        },
      }).then((result) => {
        this.$store.commit("setStagesData", result.data);
        this.info = this.$store.state.storage.storageStagesData;
      });
    } else this.info = this.$store.state.storage.storageStagesData;
  },
};
</script>

<style lang="scss">
.stages {
  margin-top: 80px;
  &__list {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 30px;
    gap: 10px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    scrollbar-width: none;
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    &__container {
      display: flex;
      align-items: center;
      &::after {
        content: var(--arrow-img);
        max-width: 140px;
        margin-left: 15px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    &__title {
      font-weight: 600;
      margin-bottom: 40px;
    }
    &__desc {
      font-weight: 700;
      max-width: 110px;
      margin-bottom: 20px;
    }
    &__date {
      color: var(--btn-gray-color1);
      font-weight: 600;
    }
  }
}
</style>
