<template>
  <div class="roadmap">
    <div class="container">
      <div class="roadmap__content">
        <h2>История форума</h2>
        <Carousel
          class="roadmap__container"
          :settings="settings"
          :breakpoints="breakpoints"
          :wrap-around="true"
          v-if="info.length !== 0"
        >
          <Slide v-for="(item, index) in info" :key="index">
            <div class="roadmap-item">
              <div class="roadmap-item__point">
                <div class="roadmap-item__image">
                  <img :src="item.main_image" alt="" />
                </div>
              </div>
              <div class="roadmap-item__year">{{ item.title }}</div>
              <div class="roadmap-item__text">{{ item.description }}</div>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { createRequest } from "@/api/requestBuilder";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import requestConfigs from "@/api/requestConfigs";
export default {
  name: "HomeRoadmap",
  components: { Carousel, Slide, Navigation },
  data() {
    return {
      info: [],
      settings: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      breakpoints: {
        959: {
          itemsToShow: 5,
          snapAlign: "center",
        },
      },
    };
  },
  beforeMount() {
    if (!this.$store.state.storage.storageRoadmapData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "history",
        },
      }).then((result) => {
        this.$store.commit("setRoadmapData", result.data);
        this.info = this.$store.state.storage.storageRoadmapData;
      });
    } else this.info = this.$store.state.storage.storageRoadmapData;
  },
};
</script>

<style lang="scss">
.roadmap {
  margin-top: 80px;
  &__content {
    h2 {
      margin-bottom: 30px;
    }
  }
  &__container {
    position: relative;
    .carousel {
      &__viewport {
        overflow: hidden;
        &::before {
          content: "";
          display: block;
          height: 5.5px;
          background-color: var(--btn-blue-color1);
          position: absolute;
          top: calc(270px + 7.5px);
          z-index: -1;
          width: 100%;
          left: 0;
        }
      }
      &__track {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        height: 600px;
      }
      &__slide {
        &--active {
          .roadmap-item__image {
            width: 220px;
            height: 200px;
            right: calc(50% - 110px);
          }
          .roadmap-item__point {
            &::after {
              width: 45px;
              height: 45px;
              top: calc(50% - 22.5px);
              left: calc(50% - 22.5px);
            }
          }
          .roadmap-item__year {
            @include text30();
          }
          .roadmap-item__text {
            display: block;
          }
        }
      }
      &__prev,
      &__next {
        color: var(--btn-blue-color1);
        position: absolute;
        top: 260px;
        cursor: pointer;
        display: block;
        &:hover {
          -webkit-text-stroke-width: 1px;
        }
        svg {
          display: none;
        }
        &::before {
          font-family: "icomoon";
          content: "\e909";
          font-size: 40px;
          display: block;
        }
      }
      &__prev {
        left: -80px;
        &::before {
          transform: rotate(90deg);
        }
      }
      &__next {
        right: -80px;
        &::before {
          transform: rotate(-90deg);
        }
      }
    }
  }
  &-item {
    padding-top: 270px;
    width: 380px;
    flex-shrink: 0;
    position: relative;
    &--active {
      .roadmap-item__image {
        width: 250px;
        height: 250px;
        right: calc(50% - 125px);
      }
      .roadmap-item__point {
        &::after {
          width: 45px;
          height: 45px;
          top: calc(50% - 22.5px);
          left: calc(50% - 22.5px);
        }
      }
      .roadmap-item__year {
        @include text30();
      }
      .roadmap-item__text {
        display: block;
      }
    }
    &__image {
      width: 150px;
      height: 150px;
      position: absolute;
      right: calc(50% - 75px);
      bottom: calc(100% + 45px);
      transition: all 0.4s ease-in-out;
      pointer-events: none;
      img {
        border-radius: 15px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__point {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: var(--btn-red-color1);
      position: absolute;
      left: 75px;
      &::after {
        content: "";
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid var(--btn-red-color1);
        background-color: var(--white);
        position: absolute;
        top: calc(50% - 17.5px);
        left: calc(50% - 17.5px);
        z-index: -1;
        transition: all 0.4s ease-in-out;
      }
    }
    &__year {
      margin-top: 45px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 10px;
      margin-left: 75px;
    }
    &__text {
      max-width: 240px;
      display: none;
      margin-left: 75px;
    }
  }
  @include adaptive(tablet-small) {
    .container {
      padding: 0;
    }
    &__content {
      h2 {
        padding: 0 20px;
      }
    }
    &-item {
      width: 200px;
      &__image {
        width: 100px;
        height: 100px;
        right: calc(50% - 50px);
        bottom: calc(100% + 30px);
      }
      &__year {
        margin-left: 35px;
        margin-top: 30px;
        font-size: 16px;
        line-height: 22px;
      }
      &__text {
        margin-left: 35px;
        font-size: 14px;
        line-height: 17px;
      }
      &__point {
        width: 10px;
        height: 10px;
        left: 35px;
        &::after {
          content: "";
          width: 20px;
          height: 20px;
          top: calc(50% - 10px);
          left: calc(50% - 10px);
        }
      }
    }
    &__container {
      .carousel {
        &__viewport {
          &::before {
            top: 272.5px;
          }
        }
        &__prev,
        &__next {
          display: none;
        }
        &__slide {
          margin-left: 10px;
          &--active {
            .roadmap-item__image {
              width: 170px;
              height: 170px;
              right: calc(50% - 85px);
            }
            .roadmap-item__point {
              &::after {
                width: 25px;
                height: 25px;
                top: calc(50% - 12.5px);
                left: calc(50% - 12.5px);
              }
            }
            .roadmap-item__year {
              font-size: 18px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
  @include adaptive(phone) {
    &-item {
      width: 140px;
      padding-top: 140px;
      &__image {
        width: 70px;
        height: 70px;
        right: calc(50% - 35px);
        bottom: calc(100% + 15px);
      }
      &__year {
        margin-left: 24px;
      }
      &__text {
        margin-left: 0;
      }
    }
    &__container {
      .carousel {
        &__slide {
          margin-left: 0;
          &--active {
            .roadmap-item__image {
              width: 100px;
              height: 100px;
              right: calc(50% - 50px);
            }
          }
        }
        &__viewport {
          &::before {
            top: 143.5px;
          }
        }
        &__track {
          height: 500px;
        }
      }
    }
  }
}
</style>
