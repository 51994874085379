<template>
  <div class="merch">
    <div class="container">
      <div class="merch__content">
        <div class="merch__content--left">
          <h2>Получи эксклюзивный мерч «ЛИГА ФОРУМ»</h2>
          <p>
            Выполняй задания мотивационной программы, войди в ТОП-30 участников
            форума и стань обладателем нашей лимитированной продукции. <br />
            <br />
            Баллы остальных участников, не вошедших в топ, конвертируются в
            LIGA-рубли, которые можно потратить на мерч, подарки от партнеров и
            форумные ценности в магазине LIGA STORE во время смены.
          </p>
        </div>
        <div class="merch__content--right">
          <Carousel
            ref="carousel"
            class="merch__slider"
            :wrapAround="true"
            :itemsToShow="5"
            snapAlign="center"
            :itemsToScroll="1"
            v-if="info.length !== 0 && !isMobile"
          >
            <Slide v-for="(item, index) in info" :key="index">
              <div class="merch-item">
                <img :src="item.main_image" :alt="item.title" />
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>
          <Carousel
            ref="carousel"
            class="merch__slider merch__slider--mobile"
            :wrapAround="true"
            :itemsToShow="1"
            v-else-if="info.length !== 0 && isMobile"
          >
            <Slide
              v-for="(item, index) in info"
              :key="index"
              style="'minWidth: 100%'"
            >
              <div class="merch-item">
                <img :src="item.main_image" :alt="item.title" />
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>
          <router-link :to="{ name: 'PrizesShop' }">
            <Button blue> ПОДРОБНЕЕ</Button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";

export default {
  name: "HomeMerch",
  components: { Button, Carousel, Slide, Navigation },
  data() {
    return {
      info: [],
      timeout: null,
      width: window.innerWidth,
    };
  },
  computed: {
    isMobile() {
      return this.width < 759;
    },
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      ``;
    },
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
  },
  beforeMount() {
    if (!this.$store.state.storage.storageMerchData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "merch",
        },
      }).then((result) => {
        this.$store.commit("setMerchData", result.data);
        this.info = this.$store.state.storage.storageMerchData;
      });
    } else this.info = this.$store.state.storage.storageMerchData;
  },
};
</script>

<style lang="scss">
.merch {
  margin-top: 80px;
  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px 20px;
    flex-wrap: wrap;
    h2 {
      margin-bottom: 30px;
    }
    &--left {
      p {
        max-width: 520px;
      }
    }
    &--right {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
    }
  }
  &__slider {
    border: 3px solid var(--btn-blue-color1);
    border-radius: 10px;
    padding: 25px 5px;
    display: flex;
    gap: 15px;
    align-items: center;
    height: 240px;
    .carousel {
      position: relative;
      &__viewport {
        display: flex;
        align-items: center;
        order: 2;
        max-width: 400px;
        overflow: hidden;
        height: 100%;
      }
      &__track {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        right: 100px;
      }
      &__slide {
        padding: 0 7.5px;
        &--active {
          margin: 0 38.5px;
          .merch-item {
            transform: scale(2);
            border-radius: 15px;
          }
        }
      }
      &__prev,
      &__next {
        color: var(--btn-blue-color1);
        top: calc(50% - 20px);
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        &:hover {
          -webkit-text-stroke-width: 1px;
        }
        svg {
          display: none;
        }
        &::before {
          font-family: "icomoon";
          content: "\e909";
          font-size: 40px;
          display: block;
        }
        margin: 0 8.75px;
      }
      &__prev {
        order: 1;
        &::before {
          transform: rotate(90deg);
          left: 10px;
        }
      }
      &__next {
        order: 3;
        &::before {
          transform: rotate(-90deg);
          right: 10px;
        }
      }
    }
    &--mobile {
      .carousel {
        &__track {
          right: unset;
        }
      }
    }
  }
  &-item {
    width: 100%;
    height: 100%;
    min-width: 90px;
    transform: scale(1);
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &__button {
      font-size: 20px;
      color: var(--btn-blue-color1);
      cursor: pointer;
      &:hover {
        -webkit-text-stroke-width: 1px;
      }
      &--left {
        transform: rotate(90deg);
      }
      &--right {
        transform: rotate(-90deg);
      }
    }
  }
  @include adaptive(desktop-small) {
    &__content {
      &--right {
        margin: 0 auto;
      }
    }
  }
  @include adaptive(tablet-small) {
    &__content {
      &--right {
        margin: 0 auto;
        width: 100%;
      }
      .carousel {
        height: unset;
        position: relative;
        &__viewport {
          max-width: unset;
        }
        &__track {
          right: unset;
          height: 100%;
          left: 50%;
        }
        &__slide {
          min-width: calc(100% + 6px);
          padding: 0;
          margin: 0;
          height: 100%;
          display: flex;
          justify-content: center;
        }
        &__prev,
        &__next {
          position: absolute;
        }
        &__prev {
        }
        &__next {
          right: 0;
        }
      }
    }
    &-item {
      min-width: unset;
      max-width: 50%;
      img {
        object-fit: cover;
      }
      &--active {
        .merch-item {
          transform: unset;
        }
      }
    }
    &__slider {
      padding: 25px 0;
      &--mobile {
        width: 100%;
      }
    }
  }
  @include adaptive(phone) {
    &__content {
      .carousel {
        &__track {
          left: 50%;
        }
        &__prev,
        &__next {
          top: calc(50% - 10px);
          &::before {
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
