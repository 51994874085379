<template>
  <div class="partners">
    <div class="container">
      <div class="partners__content">
        <h2>Партнеры</h2>
        <div class="partners__list">
          <a
            class="partners-item"
            v-for="(item, index) in info"
            :key="index"
            :href="item.description"
            target="_blank"
          >
            <img
              :src="item.main_image"
              :alt="item.title"
              width="440"
              height=""
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";

export default {
  name: "HomePartners",
  data() {
    return {
      info: [],
    };
  },
  beforeMount() {
    if (!this.$store.state.storage.storagePartnersData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "partners",
        },
      }).then((result) => {
        this.$store.commit("setPartnersData", result.data);
        this.info = this.$store.state.storage.storagePartnersData;
      });
    } else this.info = this.$store.state.storage.storagePartnersData;
  },
};
</script>

<style lang="scss">
.partners {
  margin-top: 80px;
  &__content {
    h2 {
      margin-bottom: 30px;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-content: space-between;
  }
  &-item {
    img {
      max-width: 440px;
      width: 100%;
      object-fit: contain;
      filter: grayscale(1);
      transition: all 0.4s ease;
      cursor: pointer;
    }
    &:hover {
      img {
        filter: unset;
      }
    }
  }
  @include adaptive(tablet-small) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
