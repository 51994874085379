<template>
  <div class="impressions">
    <div class="container">
      <div class="impressions__content">
        <div class="impressions__content--left">
          <h2>Как форум проходил в летнее время прошлые года</h2>
          <!--          <div class="impressions__text">-->
          <!--            <p>-->
          <!--              «ЛИГА ФОРУМ» – прекрасное место, чтобы получить много незабываемых-->
          <!--              впечатлений. Но не все об этом знают.-->
          <!--            </p>-->
          <!--            <p>-->
          <!--              Как смотришь на то, что о форуме узнают тысячи студентов по всей-->
          <!--              России? Никто другой не сможет рассказать об этом событии лучше,-->
          <!--              чем ты!-->
          <!--            </p>-->
          <!--            <p>-->
          <!--              Пиши о своих эмоциях, делись впечатлениями, приглашай друзей и-->
          <!--              знакомых регистрироваться на сайте в своих соцсетях под хештегом-->
          <!--              <b>#ЯедуНаЛИГАФОРУМ2024.</b> Сделай это лето незабываем для себя и-->
          <!--              своих друзей!-->
          <!--            </p>-->
          <!--          </div>-->
        </div>
        <div class="impressions__content--right">
          <div class="impressions__videos">
            <Video v-for="(item, index) in videos" :key="index" :video="item" />
          </div>
          <router-link :to="{ name: 'VideoGallery' }">
            <Button blue>ВСЕ ВИДЕО</Button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Video from "@/components/Blocks/Video";
import Button from "@/components/Blocks/Button";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
export default {
  name: "HomeImpressions",
  components: { Button, Video },
  data() {
    return {
      videos: [],
    };
  },
  beforeMount() {
    if (!this.$store.state.storage.storageThreeData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "video_message",
        },
      }).then((result) => {
        this.$store.commit("setVideoData", result.data);
        this.$store.commit("setThreeData", result.data.slice(0, 3));
        this.videos = this.$store.state.storage.storageThreeData;
      });
    } else this.videos = this.$store.state.storage.storageThreeData;
  },
};
</script>

<style lang="scss">
.impressions {
  margin-top: 80px;
  h2 {
    margin-bottom: 30px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    &--right {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      & > a {
        margin-top: 30px;
      }
    }
  }
  &__videos {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 20px;
  }
  &__text {
    max-width: 590px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
