<template>
  <div class="shifts" v-if="this.$store.state.storage.storageShiftsData.length">
    <div class="container">
      <div class="shifts__content">
        <h2>Смены форума</h2>
        <div class="shifts__list">
          <div
            class="shifts-item"
            v-for="(item, index) in formattedInfo"
            :key="index"
            :class="[
              {
                'shifts-item--disabled': !item.is_access_new_statement,
              },
            ]"
          >
            <div class="shifts-item__header">
              <span>{{ item.title }}</span>
              <p>{{ item.time_start }} - {{ item.time_end }}</p>
            </div>
            <div class="shifts-item__age">16 - 28 лет</div>
            <div class="shifts-item__desc">
              {{ item.text }}
            </div>
            <div class="shifts-item__reg">
              Регистрация открыта до {{ item.ended_bid }}
            </div>
            <div class="shifts-item__bot">
              <!--              <Button white @click="toLk">-->
              <!--                <span v-if="item.is_access_new_statement"> ПОДАТЬ ЗАЯВКУ </span>-->
              <!--                <span v-else>ПОДАЧА ЗАКРЫТА</span>-->
              <!--              </Button>-->
              <a
                href="https://lk.ligastudentov.com/"
                class="btn btn--white"
                target="_blank"
              >
                <span>ПОДАТЬ ЗАЯВКУ</span>
              </a>

              <div class="shifts-item__hint">
                <router-link
                  :to="{ name: 'Shift', params: { slug: item.slug } }"
                  target="_blank"
                >
                  <Icon :type="'View-On'" />
                </router-link>
                <span>Подробнее</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import Icon from "@/components/Blocks/Icon";
import moment from "moment";
export default {
  name: "HomeShifts",
  components: { Icon, Button },
  data() {
    return {
      // info: [],
      shiftKey: 0,
    };
  },
  computed: {
    info() {
      return this.$store.state.storage.storageShiftsData;
    },
    formattedInfo() {
      return this.info.map((item, index) => {
        let slug = "first";
        if (index === 1) {
          slug = "second";
        }
        if (index === 2) {
          slug = "third";
        }
        return {
          slug: slug,
          title: item.title,
          text: item.text,
          id: item.id,
          time_start: moment(item.beginning_at).format("DD"),
          time_end: moment(item.ended_at_format, "DD.MM.YYYY").format("DD.MM"),
          ended_bid: moment(item.ended_bid_campaign_at_format, "DD.MM.YYYY")
            .locale("ru")
            .format("DD MMMM"),
          is_access_new_statement: item.is_access_new_statement,
        };
      });
    },
  },
  methods: {
    toLk() {
      if (this.$store.state.user.isAuthenticated === false) {
        this.$store.commit("setCurrentPopup", {
          name: "AuthPopup",
          isShown: true,
          props: {
            popupClass: "auth-popup",
          },
        });
      } else {
        this.$router.push({ name: "LkAbout" });
      }
    },
  },
};
</script>

<style lang="scss"></style>
