// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/separator.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".members{margin-top:80px}.members__content{display:flex;align-items:flex-start;justify-content:space-between;gap:70px}.members__content--left{width:50%}.members__content--left h2{margin-bottom:30px}.members__content--right{display:flex;flex-direction:column;align-items:center;grid-gap:20px}.members__text{max-width:550px;display:flex;flex-direction:column;gap:20px}.members-list{margin-top:70px;display:flex;align-items:center;gap:35px;justify-content:flex-start}.members-item{display:flex;flex-direction:column}.members-item__container{display:flex;align-items:center}.members-item__container:last-child:after{display:none}.members-item__container:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");margin-left:35px}.members-item span{font-weight:600;font-size:50px;line-height:75px}@media screen and (max-width:767px){.members__content{flex-direction:column}.members__content--left{width:100%}}@media screen and (max-width:479px){.members-item__container:after{display:none}.members-item span{font-size:18px;line-height:22px}.members-item p{font-size:12px;line-height:14px;max-width:70px}.members-list{justify-content:center;flex-wrap:wrap}}", ""]);
// Exports
module.exports = exports;
