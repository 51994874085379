<template>
  <div class="direction">
    <div class="container">
      <h2>Дирекция</h2>
      <div class="direction__content">
        <div class="direction__list">
          <div
            class="direction-item"
            v-for="(item, index) in info"
            :key="index"
          >
            <div class="direction-item__img">
              <img :src="item.info_employees.data[0].avatar" alt="" />
            </div>
            <span>
              {{ item.info_employees.data[0].first_name }}
              {{ item.info_employees.data[0].last_name }}
            </span>
            <p>
              {{ item.info_employees.data[0].info_positions.data[0].title }}
            </p>
          </div>
        </div>
        <router-link :to="{ name: 'Direction' }" @click="scrollToTop()">
          <Button>ВСЯ КОМАНДА</Button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
export default {
  name: "HomeDirection",
  components: { Button },
  data() {
    return {
      info: [],
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  beforeMount() {
    if (!this.$store.state.storage.storageDirectionLimitData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "directorate",
          limit: 5,
        },
      }).then((result) => {
        this.$store.commit("setDirectionLimitData", result.data);
        this.info = this.$store.state.storage.storageDirectionLimitData;
      });
    } else this.info = this.$store.state.storage.storageDirectionLimitData;
  },
};
</script>

<style lang="scss">
.direction {
  margin-top: 80px;
  h2 {
    margin-bottom: 30px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__list {
    margin-bottom: 95px;
    display: flex;
    justify-content: space-between;
    gap: 20px 50px;
    overflow-y: auto;
    width: 100%;
    &::-webkit-scrollbar {
      width: 0;
    }
    scrollbar-width: none;
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 220px;
    width: 100%;
    &__img {
      margin-bottom: 25px;
      width: 220px;
      height: 220px;
      border-radius: 100%;
      background-color: var(--btn-purple-color);
      overflow: hidden;
      flex-shrink: 0;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    span {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 10px;
      text-align: center;
    }
    p {
      text-align: center;
      max-width: 200px;
    }
  }
  @include adaptive(phone) {
    &__list {
      gap: 25px;
      margin-bottom: 30px;
    }
    &-item {
      min-width: 150px;
      &__img {
        width: 100px;
        height: 100px;
      }
      span {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
}
</style>
