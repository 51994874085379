<template>
  <div class="services">
    <div class="container">
      <div class="services__content">
        <h2>Cервисы для участников</h2>
        <div class="services__list">
          <div class="services-item" v-for="(item, index) in info" :key="index">
            {{ item.title }}
            <div class="services-item__img">
              <img :src="item.additional_image" alt="" />
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";

export default {
  name: "HomeServices",
  data() {
    return {
      info: [],
    };
  },
  beforeMount() {
    if (!this.$store.state.storage.storageServicesData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "services_members",
        },
      }).then((result) => {
        this.$store.commit("setServicesData", result.data);
        this.info = this.$store.state.storage.storageServicesData;
      });
    } else this.info = this.$store.state.storage.storageServicesData;
  },
};
</script>

<style lang="scss">
.services {
  margin-top: 80px;
  &__content {
  }
  &__list {
    display: flex;
    align-items: stretch;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  &-item {
    padding: 25px;
    cursor: pointer;
    min-height: 230px;
    background-position: center;
    background-repeat: no-repeat;
    //background-size: contain;
    &:nth-child(1) {
      background-color: var(--green);
    }
    &:nth-child(2) {
      background-color: var(--btn-purple-color2);
    }
    &:nth-child(3) {
      background-color: var(--btn-blue-color1);
    }
    &:nth-child(4) {
      background-color: var(--btn-pink-color);
    }
    &:nth-child(5) {
      background-color: var(--btn-red-color1);
    }
    &:nth-child(6) {
      background-color: var(--green);
    }
    &:nth-child(odd) {
      background-image: url("~@/assets/img/decor-circle.svg");
    }
    &:nth-child(even) {
      background-image: url("~@/assets/img/decor-star.svg");
      background-size: contain;
    }
    color: var(--white);
    font-size: 30px;
    line-height: 45px;
    font-weight: 700;
    border-radius: 15px;
    max-width: 430px;
    position: relative;
    &:hover {
      .services-item__img {
        visibility: visible;
        opacity: 1;
        transform: scale(1.3);
      }
    }
    &__img {
      border-radius: 15px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      transform: scale(1);
      transition: all 0.4s ease;
      z-index: 1;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      p {
        position: absolute;
        top: 20px;
        left: 20px;
      }
    }
  }
  @include adaptive(desktop-small) {
    &-item {
      &:nth-child(odd) {
        &:hover {
          .services-item__img {
            left: 50px;
          }
        }
      }
      &:nth-child(even) {
        &:hover {
          .services-item__img {
            left: -40px;
          }
        }
      }
      &:nth-child(3) {
        background-image: url("~@/assets/img/decor-star.svg");
        background-size: contain;
      }
      &:nth-child(4) {
        background-image: url("~@/assets/img/decor-circle.svg");
        background-size: cover;
      }
    }
  }
  @include adaptive(tablet-big) {
    &__list {
      gap: 15px;
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-auto-rows: minmax(100px, auto);
    }
    &-item {
      font-size: 20px;
      min-height: 150px;
      flex-grow: 1;
      max-width: unset;
      &:nth-child(1) {
        grid-column: 1 / 5;
      }
      &:nth-child(2) {
        grid-column: 5 / 11;
      }
      &:nth-child(3) {
        grid-column: 1 / 6;
      }
      &:nth-child(4) {
        grid-column: 6 / 11;
      }
      &:nth-child(5) {
        grid-column: 1 / 5;
      }
      &:nth-child(6) {
        grid-column: 5 / 11;
      }
    }
  }
  @include adaptive(tablet-small) {
    &-item {
      padding: 12px;
      font-size: 16px;
      line-height: 21px;
    }
  }
  @include adaptive(phone) {
    &__list {
      gap: 10px;
    }
    &-item {
      font-size: 13px;
      line-height: 15px;
      min-height: unset;
      &:nth-child(1) {
        grid-column: 1 / 6;
      }
      &:nth-child(2) {
        grid-column: 6 / 11;
      }
      &:nth-child(3) {
        grid-column: 1 / 7;
      }
      &:nth-child(4) {
        grid-column: 7 / 11;
      }
      &:nth-child(5) {
        grid-column: 1 / 6;
      }
      &:nth-child(6) {
        grid-column: 6 / 11;
      }
      &:nth-child(odd) {
        &:hover {
          .services-item__img {
            left: 40px;
            transform: scale(1.7);
          }
        }
      }
      &:nth-child(even) {
        &:hover {
          .services-item__img {
            left: -90px;
            transform: scale(1.7);
          }
        }
      }
      &__img {
        font-size: 10px;
        line-height: 17px;
        min-width: 150px;
        p {
          top: 10px;
          left: 10px;
        }
      }
    }
  }
}
</style>
