<template>
  <div class="reviews">
    <div class="container">
      <div class="reviews__content">
        <h2>Отзывы</h2>
        <Carousel
          class="reviews-item__container"
          :wrap-around="true"
          v-if="info.length !== 0"
        >
          <Slide
            v-for="(item, index) in info"
            :key="index"
            style="'minWidth: 100%'"
          >
            <div class="reviews-item">
              <div class="reviews-item__img">
                <img :src="item.main_image" alt="" />
              </div>
              <div class="reviews-item__content">
                <div class="reviews-item__name">
                  {{ item.first_name }} {{ item.last_name }}
                </div>
                <div class="reviews-item__location">
                  {{ item.mini_description }}
                </div>
                <div class="reviews-item__text" v-html="item.text"></div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { createRequest } from "@/api/requestBuilder";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import requestConfigs from "@/api/requestConfigs";
export default {
  name: "HomeReviews",
  components: { Carousel, Slide, Navigation },
  data() {
    return {
      info: [],
    };
  },
  beforeMount() {
    if (!this.$store.state.storage.storageReviewsData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "reviews",
        },
      }).then((result) => {
        this.$store.commit("setReviewsData", result.data);
        this.info = this.$store.state.storage.storageReviewsData;
      });
    } else this.info = this.$store.state.storage.storageReviewsData;
  },
};
</script>

<style lang="scss">
.reviews {
  margin-top: 80px;
  color: var(--white);
  h2 {
    margin-bottom: 30px;
  }
  &-item {
    background-color: var(--btn-blue-color1);
    background-image: url("~@/assets/img/decor-waves3.svg");
    background-repeat: no-repeat;
    background-position: left top;
    border-radius: 15px;
    padding: 70px 130px 70px 70px;
    display: flex;
    align-items: center;
    gap: 50px;
    position: relative;
    max-height: 500px;
    height: 100%;
    overflow: hidden;
    width: 100%;
    &__list {
      display: flex;
      overflow: hidden;
    }
    &__text {
      white-space: pre-line;
    }
    &__container {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .carousel {
        &__viewport {
          overflow: hidden;
        }
        &__track {
          display: flex;
          flex-wrap: nowrap;
          align-items: stretch;
        }
        &__slide {
          min-width: 100%;
        }
        &__prev,
        &__next {
          color: var(--btn-blue-color1);
          position: absolute;
          top: calc(50% - 20px);
          cursor: pointer;
          display: block;
          &:hover {
            -webkit-text-stroke-width: 1px;
          }
          svg {
            display: none;
          }
          &::before {
            font-family: "icomoon";
            content: "\e909";
            font-size: 40px;
            display: block;
          }
        }
        &__prev {
          left: -80px;
          &::before {
            transform: rotate(90deg);
          }
        }
        &__next {
          right: -80px;
          &::before {
            transform: rotate(-90deg);
          }
        }
      }
    }
    &__img {
      max-width: 430px;
      width: 100%;
      position: absolute;
      bottom: 0;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: relative;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        background: var(--blur-color);
        filter: blur(85px);
        max-width: 430px;
        width: 100%;
        max-height: 430px;
        height: 100%;
        bottom: -100px;
        border-radius: 100%;
      }
    }
    &__content {
      margin-left: 500px;
    }
    &__name {
      @include text30();
      margin-top: 5px;
    }
    &__location {
      margin-bottom: 55px;
    }
    &__button {
      font-size: 40px;
      color: var(--btn-blue-color1);
      position: absolute;
      top: calc(50% - 20px);
      cursor: pointer;
      &:hover {
        -webkit-text-stroke-width: 1px;
      }
      &--left {
        left: -80px;
        transform: rotate(90deg);
      }
      &--right {
        right: -80px;
        transform: rotate(-90deg);
      }
    }
  }
  @include adaptive(desktop-mid) {
    &-item {
      &__container {
        .carousel {
          &__prev,
          &__next {
            color: var(--white);
          }
          &__prev {
            left: 10px;
          }
          &__next {
            right: 10px;
          }
        }
      }
      &__text {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  @include adaptive(desktop-small) {
    &-item {
      flex-direction: column-reverse;
      max-height: unset;
      padding: 40px;
      &__name {
        margin-top: 0;
      }
      &__content {
        margin-left: unset;
        height: 100%;
        margin-bottom: 400px;
      }
      &__container {
        .carousel {
          &__prev,
          &__next {
            top: 55px;
            &::before {
              font-size: 20px;
            }
          }
          &__prev {
            right: 80px;
            left: unset;
          }
          &__next {
            right: 40px;
          }
        }
      }
    }
  }
  @include adaptive(phone) {
    &-item {
      padding: 20px;
      &__container {
        .carousel {
          &__prev,
          &__next {
            top: 25px;
            &::before {
              font-size: 15px;
            }
          }
          &__prev {
            right: 30px;
            left: unset;
          }
          &__next {
            right: 10px;
          }
        }
      }
      &__content {
        margin-bottom: 300px;
      }
      &__img {
        max-width: 300px;
      }
    }
  }
}
</style>
