<template>
  <div class="whom">
    <div class="whom__content">
      <div class="container">
        <h2>Для кого «ЛИГА ФОРУМ»</h2>
      </div>
      <Carousel
        class="whom__list"
        :breakpoints="breakpoints"
        :settings="settings"
        v-if="info.length !== 0"
      >
        <Slide v-for="(item, index) in info" :key="index" class="whom-item">
          <div class="whom-item__block">
            <div class="whom-item__title">{{ item.title }}</div>
            <div class="whom-item__desc">
              {{ item.description }}
            </div>
            <img
              :src="item.main_image"
              :alt="item.title"
              class="whom-item__img"
            />
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "HomeForWhom",
  components: { Carousel, Slide },
  data() {
    return {
      info: [],
      settings: {
        snapAlign: "center",
        wrapAround: true,
        itemsToScroll: 1,
        itemsToShow: 1.1,
        autoplay: 5000,
      },
      breakpoints: {
        1921: {
          itemsToShow: 5.2,
        },
        1500: {
          itemsToShow: 3.5,
        },
        1400: {
          itemsToShow: 2.6,
        },
        959: {
          itemsToShow: 2.1,
        },
        767: {
          itemsToShow: 1.7,
        },
        321: {
          itemsToShow: 1.6,
        },
      },
    };
  },
  beforeMount() {
    if (!this.$store.state.storage.storageForumData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "where_forum",
        },
      })
        .then((result) => {
          this.$store.commit("setForumData", result.data);
          this.info = this.$store.state.storage.storageForumData;
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else this.info = this.$store.state.storage.storageForumData;
  },
};
</script>

<style lang="scss">
.whom {
  margin-top: 80px;
  &__content {
    margin-top: 30px;
    overflow: hidden;
  }
  &__list {
    margin-top: 30px;
    display: flex;
    align-items: stretch;
    gap: 50px;
    .carousel {
      &__viewport {
        width: 100%;
      }
      &__track {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
      }
    }
  }
  &-item {
    display: block;
    padding: 0 20px;
    color: var(--white);
    max-width: 550px;
    flex-shrink: 0;
    position: relative;
    margin-top: 120px;
    &__block {
      padding: 35px;
      height: 100%;
      border-radius: 15px;
    }
    .whom-item__block {
      background-image: url("~@/assets/img/decor-waves.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &:nth-child(4n + 1) {
      .whom-item__block {
        background-color: var(--green);
      }
    }
    &:nth-child(4n + 2) {
      .whom-item__block {
        background-color: var(--btn-blue-color1);
      }
    }
    &:nth-child(4n + 3) {
      .whom-item__block {
        background-color: var(--btn-red-color1);
      }
    }
    &:nth-child(4n + 4) {
      .whom-item__block {
        background-color: var(--btn-purple-color2);
      }
    }

    &__title {
      margin-bottom: 55px;
      text-transform: uppercase;
      @include text30();
      z-index: 1;
      position: relative;
    }
    &__desc {
      max-width: 240px;
      z-index: 1;
      position: relative;
    }
    &__img {
      position: absolute;
      bottom: -7px;
      right: 15px;
      width: 270px;
      height: 350px;
      object-fit: cover;
      pointer-events: none;
    }
  }
  @include adaptive(tablet-small) {
    &-item {
      padding: 0 10px;
      max-width: 400px;
      &__block {
        padding: 15px;
      }
      &__title {
        margin-bottom: 30px;
      }
      &__desc {
        max-width: 170px;
        font-size: 14px;
        line-height: 17px;
      }
      &__img {
        width: 210px;
        height: 280px;
      }
    }
    &__list {
      .carousel {
        &__viewport {
        }
        &__track {
          margin-left: 20px;
        }
        &__slide {
        }
      }
    }
  }
  @include adaptive(phone) {
    &-item {
      max-width: 200px;
      min-height: 110px;
      &__title {
        margin-bottom: 10px;
      }
      &__desc {
        max-width: 135px;
        font-size: 12px;
        line-height: 13px;
        position: relative;
        z-index: 1;
      }
      &__img {
        width: 110px;
        height: 140px;
      }
    }
    &__list {
      .carousel {
        &__viewport {
        }
        &__track {
          margin-left: 10px;
        }
        &__slide {
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
