<template>
  <div class="home">
    <HomeTitle />
    <HomeAbout />
    <HomeStages />
    <HomeShifts />
    <HomeForWhom />
    <!--    <HomeMembers />-->
    <HomeNewPlace />
    <HomeServices />
    <HomeImpressions />
    <HomeReviews />
    <!--    <HomeMaterials />-->
    <!--    <HomeMerch />-->
    <HomeDirection />
    <HomeRoadmap />
    <HomePartners />
    <HomeFAQ />
  </div>
</template>

<script>
import HomeForWhom from "@/components/HomeSections/HomeForWhom";
import HomeTitle from "@/components/HomeSections/HomeTitle";
import HomeAbout from "@/components/HomeSections/HomeAbout";
import HomeStages from "@/components/HomeSections/HomeStages";
import HomeShifts from "@/components/HomeSections/HomeShifts";
import HomeMembers from "@/components/HomeSections/HomeMembers";
import HomeServices from "@/components/HomeSections/HomeServices";
import HomeImpressions from "@/components/HomeSections/HomeImpressions";
import HomeReviews from "@/components/HomeSections/HomeReviews";
import HomeMaterials from "@/components/HomeSections/HomeMaterials";
import HomeMerch from "@/components/HomeSections/HomeMerch";
import HomeDirection from "@/components/HomeSections/HomeDirection";
import HomePartners from "@/components/HomeSections/HomePartners";
import HomeFAQ from "@/components/HomeSections/HomeFAQ";
import HomeRoadmap from "@/components/HomeSections/HomeRoadmap";
import HomeNewPlace from "@/components/HomeSections/HomeNewPlace";

export default {
  name: "Home",
  components: {
    HomeNewPlace,
    HomeRoadmap,
    HomeFAQ,
    HomePartners,
    HomeDirection,
    HomeMerch,
    HomeMaterials,
    HomeReviews,
    HomeImpressions,
    HomeServices,
    HomeMembers,
    HomeForWhom,
    HomeShifts,
    HomeStages,
    HomeAbout,
    HomeTitle,
  },
};
</script>

<style></style>
