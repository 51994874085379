<template>
  <section class="section-title">
    <div id="video-bg" class="video-bg">
      <video
        width="100%"
        preload="auto"
        autoplay="autoplay"
        muted=""
        loop="loop"
        v-if="!isMobile"
      >
        <source src="@/assets/img/header-video.mp4" type="video/mp4" />
      </video>
    </div>

    <div class="header__main">
      <div class="main__logo">
        <img
          :src="
            $store.state.designMode === 'winter'
              ? require('@/assets/img/logo-winter.webp')
              : require('@/assets/img/logo.svg')
          "
          alt="Лого"
          width="180"
          height="70"
        />
      </div>
      <h1 class="header__main__title">
        СТУДЕНЧЕСКИЙ ОБРАЗОВАТЕЛЬНЫЙ ФОРУМ «ЛИГА ФОРУМ»
      </h1>
      <div class="main__location">
        <div class="main__location-item">
          <Icon :type="'location'" />
          <p>Альметьевск</p>
        </div>
        <div class="main__location-item">
          <Icon :type="'calendar'" />
          <p class="main__location__date">3-19 августа</p>
        </div>
      </div>
      <button
        class="header__main__button"
        @click="readArticle"
        aria-label="Проигрывать видео"
      >
        <i
          class="icon-play video_button"
          data-src-video="https://vk.com/video-182279308_456239346"
        ></i>
      </button>
    </div>
  </section>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
export default {
  name: "HomeTitle",
  components: { Icon },
  data() {
    return {
      width: window.innerWidth,
    };
  },
  computed: {
    isMobile() {
      return this.width < 959;
    },
  },
  methods: {
    readArticle() {
      this.$store.commit("setCurrentPopup", {
        name: "VideoPopup",
        isShown: true,
        props: {
          popupClass: "popup-video",
          isTitle: true,
        },
      });
    },
    updateWidth() {
      this.width = window.innerWidth;
      ``;
    },
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
  },
  beforeMount() {
    this.updateWidth;
  },
};
</script>

<style lang="scss">
.header {
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    position: relative;
    color: var(--white);
    h1 {
      font-size: 50px;
      line-height: 75px;
      font-weight: 700;
      text-align: center;
      max-width: 680px;
      text-transform: uppercase;
    }
    &__button {
      outline: none !important;
      border: 0;
      background-color: transparent;
      margin-top: 50px;
      i {
        line-height: 1;
        font-size: 67px;
        color: var(--white);
        &:hover {
          -webkit-text-stroke-width: 1px;
        }
      }
    }
  }
  @include adaptive(tablet-big) {
    &__main {
      &__button {
      }
    }
  }
  @include adaptive(tablet-small) {
    &__main {
      h1 {
        font-size: 30px;
        line-height: 45px;
      }
      &__button {
        i {
          font-size: 45px;
        }
      }
    }
  }
  @include adaptive(phone) {
    &__main {
      h1 {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}
.main {
  &__logo {
    margin-bottom: 45px;
    position: relative;
    z-index: 1;
    max-width: 220px;
    width: 100%;
    max-height: 70px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__location {
    display: flex;
    justify-content: space-between;
    max-width: 300px;
    width: 100%;
    margin-top: 30px;
    &-item {
      display: flex;
      align-items: center;
      i {
        font-size: 20px;
        margin-right: 10px;
      }
    }
    p {
      font-size: 16px;
      line-height: 23px;
      font-weight: 600;
    }
  }
  @include adaptive(tablet-small) {
    &__logo {
      display: none;
    }
  }
  @include adaptive(phone) {
    &__location {
      &-item {
        i {
          font-size: 17px;
        }
      }
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}
</style>
