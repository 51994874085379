<template>
  <div class="members">
    <div class="container">
      <div class="members__content">
        <div class="members__content--left">
          <h2>Участники со всей России</h2>
          <div class="members__text">
            <p>
              Каждая точка на карте объединяет студентов одного региона России,
              которые уже решили поехать на «ЛИГА ФОРУМ» в этом году.
            </p>
            <p>
              Когда ты подашь заявку, на счётчике прибавится +1 потенциальный
              участник.
            </p>
          </div>
          <div class="members-list">
            <div class="members-item__container">
              <div class="members-item">
                <span>{{ info.line_counts?.vuz }}</span>
                <p>Студентов ВУЗов</p>
              </div>
            </div>
            <div class="members-item__container">
              <div class="members-item">
                <span>{{ info.line_counts?.suz }}</span>
                <p>Студентов СПО</p>
              </div>
            </div>
            <div class="members-item__container">
              <div class="members-item">
                <span>{{ regionsCount }}</span>
                <p>Субъектов РФ</p>
              </div>
            </div>
          </div>
        </div>
        <div class="members__content--right">
          <img src="@/assets/img/map.webp" alt="" width="768" height="" />
          <Button red @click="openPopup">Посмотреть</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import Button from "@/components/Blocks/Button";

export default {
  name: "HomeMembers",
  components: { Button },
  data() {
    return {
      info: [],
    };
  },
  computed: {
    regionsCount() {
      return this.info.maps_info?.data.filter((item) => {
        return item.count_statements > 0;
      }).length;
    },
  },
  methods: {
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "MapPopup",
        isShown: true,
        props: {
          popupClass: "map-popup",
          info: this.info,
        },
      });
    },
  },
  beforeMount() {
    if (!Object.keys(this.$store.state.storage.storageMembersData).length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "maps",
        },
      }).then((result) => {
        this.$store.commit("setMembersData", result);
        this.info = this.$store.state.storage.storageMembersData;
      });
    } else this.info = this.$store.state.storage.storageMembersData;
  },
};
</script>

<style lang="scss">
.members {
  margin-top: 80px;
  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 70px;
    &--left {
      width: 50%;
      h2 {
        margin-bottom: 30px;
      }
    }
    &--right {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 20px;
    }
  }
  &__text {
    max-width: 550px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &-list {
    margin-top: 70px;
    display: flex;
    align-items: center;
    gap: 35px;
    justify-content: flex-start;
  }
  &-item {
    display: flex;
    flex-direction: column;
    &__container {
      display: flex;
      align-items: center;
      &:last-child {
        &::after {
          display: none;
        }
      }
      &::after {
        content: url("~@/assets/img/separator.svg");
        margin-left: 35px;
      }
    }
    span {
      font-weight: 600;
      font-size: 50px;
      line-height: 75px;
    }
  }
  @include adaptive(tablet-small) {
    &__content {
      flex-direction: column;
      &--left {
        width: 100%;
      }
    }
  }
  @include adaptive(phone) {
    &-item {
      &__container {
        &::after {
          display: none;
        }
      }
      span {
        font-size: 18px;
        line-height: 22px;
      }
      p {
        font-size: 12px;
        line-height: 14px;
        max-width: 70px;
      }
    }
    &-list {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}
</style>
