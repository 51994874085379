<template>
  <section class="about">
    <div class="container">
      <div class="about__content">
        <div class="about__content--left">
          <h2>Что такое «ЛИГА ФОРУМ»?</h2>
          <p>
            «Лига форум» – это уникальная образовательная площадка, где каждый
            участник имеет возможность обогатить свой интеллектуальный багаж,
            завести новые знакомства и найти единомышленников.
          </p>
          <p>
            Летом «Лига форум» приобретает особую привлекательность, ведь именно
            в это время мечты превращаются в действия. Здесь ты сможешь не
            только учиться и обмениваться опытом, но и воплощать свои идеи в
            жизнь, находя вдохновение и поддержку от сообщества
            единомышленников.
          </p>
          <!--          <Button red @click="toLk">ПОДАТЬ ЗАЯВКУ</Button>-->
          <a
            href="https://lk.ligastudentov.com/"
            class="btn btn--red"
            target="_blank"
          >
            ПОДАТЬ ЗАЯВКУ
          </a>
        </div>

        <div
          class="about__content--right"
          v-if="$store.state.designMode === 'winter'"
        >
          <img src="@/assets/img/about-img.svg" alt="" />
          <!--                    <span>«ЛИГА ФОРУМ» - твое место для новых открытий</span>-->
          <!--          -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from "@/components/Blocks/Button";
export default {
  name: "HomeAbout",
  components: { Button },
  methods: {
    toLk() {
      if (this.$store.state.user.isAuthenticated === false) {
        this.$store.commit("setCurrentPopup", {
          name: "AuthPopup",
          isShown: true,
          props: {
            popupClass: "auth-popup",
          },
        });
      } else {
        this.$router.push({ name: "LkAbout" });
      }
    },
  },
};
</script>

<style lang="scss">
.about {
  margin-top: 60px;
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    &--left {
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: flex-start;
      p {
        max-width: 570px;
      }
    }
    &--right {
      font-size: 30px;
      line-height: 45px;
      font-weight: 700;
      color: var(--black2);
      //padding: 15px 130px;
      //border: 4px solid var(--yellow);
      border-radius: 15px;
      display: grid;
      max-width: 660px;
      span {
        margin: 0 auto;
        display: block;
      }
    }
    img {
      flex-shrink: 1;
      object-fit: contain;
    }
  }
  @include adaptive(phone) {
    &__content {
      &--right {
        display: none;
      }
    }
  }
}
</style>
